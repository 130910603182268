import CreateModuleDialog from "@/components/applications/module/CreateModuleDialog.vue";
import EditModuleDialog from "@/components/applications/module/EditModuleDialog.vue";
import DeleteModuleDialog from "@/components/applications/module/DeleteModuleDialog.vue";
import SearchInput from "@/components/global/SearchInput.vue";

export default {
  name: "Modules",

  data: () => ({
    search: "",
    headers: [
      {
        text: "Aplicación",
        value: "app_name",
        align: "start",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Nombre",
        value: "name",
        align: "start",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Descripción",
        value: "description",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Estado",
        value: "active",
        align: "center",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        class: "primary--text",
      },
    ],
    editedItem: {},
    deletedItem: {},
    currentPage: 1,
    appSelected: null,
    timeout: null,
    limit: 5,
    rowsPerPage: [{ value: 5 }, { value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }]
  }),

  computed: {
    modules() {
      return this.$store.getters["modules/getModules"];
    },
    applications() {
      return this.$store.getters["applications/getApplications"];
    },
    widthPage() {
      return this.$vuetify.breakpoint.width;
    },
    paginationPages() {
      return this.$store.getters["modules/getPaginationPages"];
    },
    isLoading() {
      return this.$store.state.modules.isLoading;
    },
  },

  watch: {},

  created() {
    this.$store.dispatch("modules/getModules", { page: 1 });
    this.$store.dispatch("modules/updateCurrentPage", 1);
    this.$store.dispatch("applications/getApplications");
  },

  methods: {
    editItem(item) {
      this.editedItem = { ...item };
    },

    deleteItem(item) {
      this.deletedItem = { ...item };
    },

    listenSelect(app_id) {
      this.currentPage = 1;
      this.search = "";
      this.$store.dispatch("modules/getModules", {
        page: this.currentPage,
        app_id,
      });
    },

    listenPagination() {
      this.$store.dispatch("modules/getModules", {
        page: this.currentPage,
        app_id: this.appSelected,
        search: this.search,
        limit: this.limit
      });
      this.$store.dispatch("modules/updateCurrentPage", this.currentPage);
    },

    resetFilter() {
      this.appSelected = null;
      this.search = "";
      this.currentPage = 1;
    },
  },
  components: {
    CreateModuleDialog,
    EditModuleDialog,
    DeleteModuleDialog,
    SearchInput,
  },
};
